import { template as template_b836dcd7c1914752bd7549bc5e672cd7 } from "@ember/template-compiler";
const FKLabel = template_b836dcd7c1914752bd7549bc5e672cd7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
