import { template as template_dbcb38b046184bbf8d4c3109f8b238d9 } from "@ember/template-compiler";
const FKText = template_dbcb38b046184bbf8d4c3109f8b238d9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
