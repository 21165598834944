import { template as template_fd9ef1982d914e1ea63c964300495329 } from "@ember/template-compiler";
const WelcomeHeader = template_fd9ef1982d914e1ea63c964300495329(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
